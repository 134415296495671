<template>
  <el-row class="reports-body d-flex flex-direction-col h-100">
    <div class="white-back-color h-100">
      <div id="chart">
        <apexchart
          type="line"
          height="350"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </div>
  </el-row>
</template>

<script>
// #70ad46 yashil
// #4473c5 ko'k
// #e40808 qizil
// #f0c122 sariq
export default {
  name: 'dashboard2',

  data() {
    return {
      series: [
        {
          name: 'Income',
          type: 'column',
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
        },
        {
          name: 'Cashflow',
          type: 'column',
          data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
        },
        {
          name: 'Revenue',
          type: 'line',
          data: [20, 29, 37, 36, 44, 45, 50, 58]
        },
        {
          name: 'Revenue2',
          type: 'line',
          data: [2, 30, 37, 46, 15, 60, 40, 98]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [1, 1, 4, 4]
        },
        title: {
          text: 'XYZ - Stock Analysis (2009 - 2016)',
          align: 'left',
          offsetX: 110
        },
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
        },
        yaxis: [
          {
            show: false,
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB'
              }
            },
            // title: {
            //   text: 'Income (thousand crores)',
            //   style: {
            //     color: '#008FFB'
            //   }
            // },
            tooltip: {
              enabled: false
            }
          },
          {
            show: false,
            seriesName: 'Income',
            opposite: false,
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: true,
              color: '#00E396'
            },
            labels: {
              style: {
                colors: '#00E396'
              }
            }
            // title: {
            //   text: 'Operating Cashflow (thousand crores)',
            //   style: {
            //     color: '#00E396'
            //   }
            // }
          },
          {
            show: false,
            seriesName: 'Revenue',
            opposite: false,
            axisTicks: {
              show: false
            },
            axisBorder: {
              show: false,
              color: '#FEB019'
            },
            labels: {
              style: {
                colors: '#FEB019'
              }
            }
            // title: {
            //   text: 'Revenue (thousand crores)',
            //   style: {
            //     color: '#FEB019'
            //   }
            // }
          }
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          }
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      }
    }
  }
}
</script>
